@use '/src/assets/scss/variables' as v;

.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  gap: 40px;
  
  .each-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @include v.generalBorderStyle;
    height: 380px;
    padding-bottom: 20px;

    .block-title {
      font-size: v.$title2;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      border-bottom: 2px solid v.$topbarBgColor;
      margin-bottom: 20px;
    }

    .feature-items {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;

      gap: 20px;

      .feature-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        min-height: 40px;
        
        button{
          font-size:  16px !important;
        }
      }
    }
  }
}