@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';

//common
.table-container {
    display: flex;
    justify-content: center;
    min-width: 960px;
    // width: 100%;
    border-radius: 8px;
    // border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
    // background: #FFF;
    border: 1px solid v.$grey25;
    background: v.$white;
    
    .loading-circle {
        padding: 5px 0;
        color: v.$green2 ;
    }
    text-size-adjust: 10px;
}

//---------------------------------------------------------------------------
// color
.bgc-green {
    background-color: v.$green;
}

.bgc-grey50 {
    background-color: v.$grey50;
}

.bgc-grey75 {
    background-color: v.$grey75;
}

.bgc-grey100 {
    background-color: v.$grey100;
}

.bgc-topbar-blue {
    background-color: v.$topbarBgColor;
}

.color-white {
  color: v.$white; 
  
  &.MuiTableCell-body {
    color: v.$white;
  }
}

//---------------------------------------------------------------------------
//font
// .h1-darkGreen {
//   @include heading1-darkGreen;
// }

// .b1-grey100 {
//     @include body1-grey100;
// }

// .b1strong-darkGreen {
//     @include body1strong-darkGreen;
// }

// .b2-amwayBlack {
//     @include body2-amwayBlack;
// }
// .b2-darkGreen {
//     @include body2-darkGreen;
// }
// .b2-grey100 {
//     @include body2-grey100;
// }

// .b2strong-white {
//     @include body2strong-white;
// }

// .b2strong-darkGreen {
//     @include body2strong-darkGreen;
// }

