@use 'src/assets/scss/_variables' as v;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500&family=Open+Sans:wght@500&display=swap');

$padding-content-child: 50px;

.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 98vh;

    background-image: url("../../img/LoginBg.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .system-name {
        font-size: 40px;
        margin-bottom: 20px;
        font-family: 'Noto Sans TC', sans-serif;
    }

    .content {
        @include v.generalBorderStyle;
        max-width: 490px;
        background-color: white;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);

        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;

        .top-illustration {
            margin-bottom: $padding-content-child;
            font-size: v.$title1;
        }


        .login-filed {
            width: 70%;
            margin-bottom: calc($padding-content-child/2);
        }

        .input-field {
            margin-top: 10px;
        }

        .errBlock {
            width: 60%;
            margin-bottom: 20PX;
            display: flex;
            align-items: center;
            @include v.generalBorderRadius;
        }

        .login-btn-block {
            width: 60%;
        }

        .login-btn {
            width: 100%;
            height: 48px;
            border-radius: 80px;
            box-shadow: none;
            text-transform: none;

            &:hover {
                // cursor: pointer;
                //   border: 1px solid v.$darkGreen;
            }

            &:enabled {
                background: v.$topbarBgColor;
                //   @include body2strong-darkGreen;
            }
        }
    }
}