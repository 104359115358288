@use '/src/assets/scss/variables' as v;

.content {
  padding: 20px 24px !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cancel-btn {
  color: v.$error !important;
}

.confirm-btn {
  color: v.$topbarBgColor !important;
}

.default-column-names{
  color: v.$grey100;
  // padding-left: 20px;
}