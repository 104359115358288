@use 'src/assets/scss/_variables' as v;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500&family=Open+Sans:wght@500&display=swap');

.main{
    background-color: v.$topbarBgColor !important;

    .topbar{
        display: flex;
        justify-content: space-between;
        padding-left: 45px;
        padding-right: 60px;
    }

    @media screen and (max-width: 900px) {
        .topbar {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
.system-name {
    font-size: 20px;
    font-family: 'Noto Sans TC', sans-serif !important;
    margin-right: 20px !important;
}

.username-block {
    font-size: 16px !important;
      &:hover {
        background-color: transparent !important;
    }
}