@use 'src/assets/scss/_variables' as v;

.loading-block {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

    .loading-circle {
        color: v.$topbarBgColor;
    }
}