@use 'src/assets/scss/_variables' as v;

.search-text {
    box-sizing: border-box;
    display: flex;
    height: 40px;
    padding: 8px 16px 6px 16px;
    align-items: center;

    @include v.generalBorderStyle;

    @media screen and (max-width: 900px) {
        width: 100%;
    }
}
