// -----colors-------------------------------------------------------
$amwayBlack: #2c2c2c;
$success: #01B46822;
$success2: #01B468;
$error: #ff2d2db2;
$warning: #fadc55;

$white: #ffffff;
$grey25: #f4f4f4;
$grey50: #dfdfdf;
$grey75: #bab9b8;
$grey100: #6f6f6f;

$green: #0dddcd;
$green2: #3cb6ad;
$darkGreen: #073431;

$topbarBgColor: #4d7dae;
$tableRowSelected: rgba(25, 118, 210, 0.08);

// -----fonts-------------------------------------------------------
$title1: 25px;
$title2: 20px;

$text: 16px;

// -----others-------------------------------------------------------
@mixin generalBorderRadius {
    border-radius: 5px;
}

@mixin generalBorderStyle {
    @include generalBorderRadius;
    border: 1px solid $grey50;
}

@mixin page-title{
    font-size: $title1;
    display: flex;
    align-items: center;
    // gap: 16px;
    margin-bottom: 20px;
}

:export {
    success2:$success2;
    error: $error;
    grey50: $grey50;
    grey75: $grey75;
    grey100: $grey100;
    title2:$title2;
}