@use '/src/assets/scss/variables' as v;

.pagination-block {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 8px;

    .bar-page,
    .go-to-page-block,
    .go-to-btn {
        box-sizing: border-box;

        display: flex;
        height: 40px;
        padding: 10px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border: 1px solid v.$grey50;
        background: v.$white;
        cursor: pointer;
    }

    .divider {
        border-left: 2px solid v.$grey25;
        height: 40px;
        margin-left: 16px;
    }

    .go-to-page-block,
    .go-to-btn {
        box-sizing: border-box;

        display: flex;
        height: 40px;
        padding: 10px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border: 1px solid v.$grey50;

        background: v.$white;

        &:hover{
            cursor: pointer;
        }
    }

    .go-to-page-block {
        margin-left: 15px;

        &:focus-within {
            border-color: v.$darkGreen;
        }

        padding: 10px 14px;

        .go-to-page-input {
            outline: none;
            border: none;
            width: 20px;

            &[type='number'] {
                -moz-appearance: textfield;
            }

            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    .go-to-btn {
        margin-left: 5px;

        &:hover {
            background-color: v.$grey25;
        }
    }

}


$select-page-bg: v.$grey25;
// $select-page-bg: v.$green; //debug
$unselect-page-bg: white;

:export {
    selectBg: $select-page-bg;
    unselectBg: $unselect-page-bg;
}