@use 'src/assets/scss/_variables' as v;

.main {
  display: flex;
  flex-direction: column;
  height: 100%;

  .page-title-block{
    padding-bottom: 16px;
  }

  .tabs {
    display: flex;
    gap: 16px;
    margin-bottom: 20px;

    .protein-tab,
    .peptide-tab {
      @include v.generalBorderStyle;
      padding: 8px 25px;

      &:hover {
        cursor: pointer;
      }
    }

    .is-select {
      background-color: v.$tableRowSelected;
    }


  }
}