@use './variables' as v;

/* 
fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
  ].join(","),
 */

//---------------------------------------------------------------------------
//  Font
//---------------------------------------------------------------------------
/* Display 1 */
@mixin display1 {
  /* Display 1 */
  // font-family: Myriad Pro;
  // font-size: 96px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 105.6px */
  
  //font-family: v.$fontPTSans;
  font-size: 90px;
}

//---------------------------------------------------------------------------
/* Heading 1 */
@mixin heading1 {
  /* Heading 1 */
  // font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  // font-size: 48px;
  line-height: 120%;
  //font-family: v.$fontPTSans;
  font-size: 46px;

  /* or 58px */
  display: flex;
  align-items: center;
  text-align: center;
}

/* Heading 2 */
@mixin heading2 {
  /* Heading 2 */
  // font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  // font-size: 40px;
  line-height: 120%;
  //font-family: v.$fontPTSans;
  font-size: 38px;

  /* identical to box height, or 48px */
  display: flex;
  align-items: center;
}

/* Heading 3 */
@mixin heading3 {
  /* Heading 3 */
  // font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  // font-size: 32px;
  line-height: 130%;
  //font-family: v.$fontPTSans;
  font-size: 30px;

  /* identical to box height, or 42px */
  display: flex;
  align-items: center;
}

//styleName: Heading/H4;
@mixin heading4 {
  //styleName: Heading/H4;
  // font-family: Myriad Pro;
  // font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;

  //font-family: v.$fontPTSans;
  font-size: 22px;
}

//---------------------------------------------------------------------------
/* Body 1 */
@mixin body1 {
  /* Body 1 */
  // font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  // font-size: 18px;
  line-height: 150%;
  //font-family: v.$fontPTSans;
  font-size: 16px;

  /* or 27px */
  display: flex;
  align-items: center;
  text-align: center;
}

/* Body 1 - Strong */
@mixin body1strong {
  /* Body 1 - Strong */
  // font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  // font-size: 18px;
  line-height: 150%;
  //font-family: v.$fontPTSans;
  font-size: 16px;

  /* identical to box height, or 27px */
  display: flex;
  align-items: center;
}

/* Body 2 */
@mixin body2 {
  /* Body 2 */
  // font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 400;
  // font-size: 16px;
  line-height: 150%;
  //font-family: v.$fontPTSans;
  font-size: 14px;

  /* identical to box height, or 24px */
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
}

/* Body 2 - Strong */
@mixin body2strong {
  /* Body 2 - Strong */
  // font-family: Myriad Pro;
  // font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: 0.32px;
  
  //font-family: v.$fontPTSans;
  font-size: 14px;

  /* identical to box height, or 24px */
  display: flex;
  align-items: center;
}

//---------------------------------------------------------------------------
// Font + Color
//---------------------------------------------------------------------------
/* Display 1 */
@mixin display1-darkGreen {
  @include display1;
  
  // color: var(--dark-green, #073431);
  color: v.$darkGreen;
}

//---------------------------------------------------------------------------
/* Body 1 */
@mixin body1-grey100 {
  @include body1;

  /* Neutral/Grey 100 - Body */
  // color: #6F6F6F;
  color: v.$grey100;
}

@mixin body1-darkGreen {
  @include body1;

  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

@mixin body1-amwayBlack {
  @include body1;

  /* Neutral/Amway Black - Heading */
  // color: #2C2C2C;
  color: v.$amwayBlack;
}

@mixin body1-white {
  @include body1;

  /* Neutral/White */
  // color: #FFFFFF;
  color: v.$white;
}
//---------------------------------------------------------------------------
@mixin body1strong-amwayBlack {
  @include body1strong;

  /* Neutral/Amway Black - Heading */
  // color: #2C2C2C;
  color: v.$amwayBlack;
}

@mixin body1strong-darkGreen {
  @include body1strong;

  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

@mixin body1strong-white {
  @include body1strong;

  /* Neutral/White */
  // color: #FFFFFF;
  color: v.$white;
}
//---------------------------------------------------------------------------
/* Body 2 */
@mixin body2-white {
  @include body2;

  /* Neutral/White */
  // color: #FFFFFF;
  color: v.$white;
}

@mixin body2-amwayBlack {
  @include body2;

  /* Neutral/Amway Black - Heading */
  // color: #2C2C2C;
  color: v.$amwayBlack;
}

@mixin body2-darkGreen {
  @include body2;

  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

@mixin body2-grey100 {
  @include body2;

  /* Neutral/Grey 100 - Body */
  // color: #6F6F6F;
  color: v.$grey100;
}

@mixin body2-white05 {
  @include body2;

  /* Neutral/White */
  // color: #FFFFFF;
  color: v.$white;

  opacity: 0.5;
}

@mixin body2-white {
  @include body2;

  /* Neutral/White */
  // color: #FFFFFF;
  color: v.$white;
}

//---------------------------------------------------------------------------
/* Body 2 - Strong */
@mixin body2strong-white {
  @include body2strong;

  /* Neutral/White */
  // color: #FFFFFF;
  color: v.$white;
}

@mixin body2strong-darkGreen {
  @include body2strong;

  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

//---------------------------------------------------------------------------
/* Heading 1 */
//---------------------------------------------------------------------------
@mixin heading1-darkGreen {
  @include heading1;

  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

//---------------------------------------------------------------------------
/* Heading 2 */
//---------------------------------------------------------------------------
@mixin heading2-darkGreen {
  @include heading2;

  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

//---------------------------------------------------------------------------
/* Heading 3 */
//---------------------------------------------------------------------------
@mixin heading3-darkGreen {
  @include heading3;

  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

//---------------------------------------------------------------------------
//styleName: Heading/H4;
//---------------------------------------------------------------------------
@mixin heading4-white {
  @include heading4();

  // background: #FFFFFF;
  color: v.$white;
}
