@use 'src/assets/scss/_variables' as v;

.main {
    .page-title-block {
       @include v.page-title;
       margin-bottom: 10px;
        // justify-content: space-between;
        .btn-block {
            display: flex;
            gap: 6px;
        }

        // button {
        //     padding: 0;
        //     width: 20px;
        //     height: 20px;
        // }
    }
    .page-title-block2 { // ProteinPeptideSettings.jsx
        @include v.page-title;
        margin-bottom: 10px;
         justify-content: space-between;
         .btn-block {
             display: flex;
             gap: 6px;
         }
 
         // button {
         //     padding: 0;
         //     width: 20px;
         //     height: 20px;
         // }
     }
    .filter-search-block {
        padding: 20px 0 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;


        .search-bar {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }
    .footer-button-block{
        padding: 20px 0 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}