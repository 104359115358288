@use 'variables' as v;
// @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Montserrat&display=swap');

body {
  margin: 0;
  // font-family:
  // -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  // 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  // v.$fontPTSans !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
* {
  font-family: 'PT Sans', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  // TODO: This is an example for using variable of scss, can be deleted
  color: v.$amwayBlack;
  text-decoration: none;
}

.link {
  color: #1e88e5;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

button {
  text-transform: none !important;
}

.App {
  // display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* hide arrows of number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}