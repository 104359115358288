@use 'src/assets/scss/_variables' as v;

.layout {
  display: flex;
  flex-direction: column;
  min-width: 1040px;
  max-width: 1440px;

  .page-title-block {
    @include v.page-title;
    gap: 4px;
    margin-right: 10px;
}
}
.TableHeadCell{
  font-size: 30px;
  width: 190px;
}
.page-title-block{
  justify-content: space-between;
}