@use '/src/assets/scss/variables' as v;

@mixin block-padding {
    padding: 30px;
}

.main {
    display: flex;
    flex-direction: column;
    height: 95vh;

    .page-title-block {
        @include v.page-title;
        gap: 4px;
    }

    .settings-block {
        display: flex;
        flex-direction: row;
        gap: 20px;
        height: 100%;
    }
}

.fullHeightTableContainer {
    height: 97%;
    overflow-y: auto;
    max-width: 100vw;
    overflow-x: auto;
}


.fullHeightTableContainer .MuiTable-stickyHeader th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}


.cls-block {
    flex: 3;
}

.source-block {
    flex: 4;
}

.cls-block,
.source-block {
    @include v.generalBorderStyle;
    @include block-padding;
}

.cls-title-block,
.source-title-block {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    .btn-title {
        margin-left: 16px;
        gap: 20px;

        button {
            padding-top: 0;
            padding-bottom: 0;
            width: 30px;
            height: 30px;
        }
    }
}

.cls-data-block,
.source-data-block {
    padding: 60px 0;
}

.cls-item,
.source-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0 !important;

    @include v.generalBorderRadius;

    &:hover {
        background-color: v.$grey25;
        cursor: pointer;
    }
}

.container {
    position: relative;
    @include v.generalBorderRadius;

    &:hover .overlay {
        background-color: rgba($color: v.$grey25, $alpha: 0.2);
        cursor: pointer;
        display: flex;
    }
}

.content {
    z-index: 0;
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include v.generalBorderRadius;
    display: none;
    justify-content: end;
    align-items: center;
    padding-right: 20px;
}

.selected-cls-item {
    border: 1px solid v.$darkGreen;
}

.error-block {
    padding: 30px 20px;
}