@use '/src/assets/scss/variables' as v;
.button-block {
  display: flex;
  align-items: center;
  justify-content: center;

  .button-save {
    margin: 10px;
    background-color: #4d7dae;
  }
}

.table-raw {
  // width: 100%;
  max-width: 800px;

  .row-textfield {
    width: 100%;
    max-width: 800px;

  }

  .item-text {
    font-size: 21px;
    width: 100%;
    max-width: 800px;

  }
}

//Peptide Classification
.block1 {
  display: flex;
  flex-direction: row;
  margin: 10px;
  align-items: center;
  justify-content: center;

  .title {
    width: 200px;
  }
}

.selectItem-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  gap: 8px;

  .button-checked {
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    background-color: #4d7dae;
    border-radius: 100px;
  }
}